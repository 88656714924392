import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMonthDateYear } from 'helpers/getMonthDateYear';

interface ILastUpdated {
	updated_at: string;
}

const LastUpdated: React.FC<ILastUpdated> = ({ updated_at }) => {
	return (
		<>
			{updated_at && <Bold>Article last updated on {getMonthDateYear(updated_at)}</Bold>}
			<Links>
				<Name>
					<Link to='/editorial-policy'>View our Editorial Policy</Link>
				</Name>
				<Name>
					<Link to='/advertising-policy'>{`Read our Ad & Sponsorship Policy`}</Link>
				</Name>
				<Name>
					<Link to='/editorial-team'>Meet our Editorial Team</Link>
				</Name>
			</Links>
		</>
	);
};

const Links = styled.div`
	& > div {
		margin: 10px 0;
		&:first-child {
			margin-top: 20px;
		}
	}
`;

const Name = styled.div`
	& a {
		color: #333e48;
		transition: all 0.3s ease;

		&:hover {
			color: #424bec;
			text-decoration: none;
		}
	}
`;
const Bold = styled.span`
	font-weight: 600;
`;

export default LastUpdated;
