export const getMonthDateYear = (itemDate: string | null) => {
	if (itemDate === null) return null;
	const reviewedDate = new Date(itemDate);

	const isValidDate = (dateObject: Date) => new Date(dateObject).toString() !== 'Invalid Date';
	if (isValidDate(reviewedDate)) {
		const month = reviewedDate.toLocaleString('en-AU', { month: 'long', timeZone: 'Australia/Sydney' });
		const date = reviewedDate.toLocaleString('en-AU', { day: 'numeric', timeZone: 'Australia/Sydney' });
		const year = reviewedDate.toLocaleString('en-AU', { year: 'numeric', timeZone: 'Australia/Sydney' });

		return `${month} ${date}, ${year}`;
	}
	return null;
};
