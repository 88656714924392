import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMonthDateYear } from 'helpers/getMonthDateYear';
import { CreatorInArticle } from 'apiFolder/directus-main';

import { Paragraph } from 'components/common/Styles';
import ImageContainerComponent from 'components/common/image/ImageContainer';

interface ISpecialist {
	specialists: CreatorInArticle[];
	dateBy: string;
	titlePref: string;
	textPref: string;
	isTab?: boolean;
}

const SpecialistInfo: React.FC<ISpecialist> = ({ specialists, dateBy, titlePref, textPref, isTab = false }) => {
	return (
		<div>
			{specialists.length > 0
				? specialists.map((item) => (
						<Specialist key={item.personId}>
							<AuthorImg isTab={isTab}>
								<ImageContainerComponent
									src={item.profilePicture! && `${item.profilePicture}?key=medium`}
									alt={item.name}
									thumb={item.profilePicture! && `${item.profilePicture}?key=small`}
									isRound
								/>
							</AuthorImg>
							<SpecialistContent isTab={isTab}>
								<Name>
									<Bold>{titlePref}</Bold> <Link to={item.slug}>{item.name}</Link>
								</Name>
								<Text isTab={isTab}>
									{item.medicalTitle}
									{item.education && item.education.length > 0 && `, ${item.education[0].university}`}
								</Text>
								{dateBy && (
									<Text isTab={isTab}>
										{textPref} {getMonthDateYear(dateBy)}
									</Text>
								)}
								{isTab && item.miniDescription && <Description>{item.miniDescription}</Description>}
							</SpecialistContent>
						</Specialist>
				  ))
				: null}
		</div>
	);
};

const Specialist = styled.div`
	padding-bottom: 26px;
	display: flex;
`;
const SpecialistContent = styled.div<{ isTab: boolean }>`
	display: flex;
	flex-direction: column;
	margin-left: ${({ isTab }) => (isTab ? '15px' : '7.5px')};
`;
const AuthorImg = styled.div<{ isTab: boolean }>`
	flex: 0 0 ${({ isTab }) => (isTab ? '70px' : '38px')};
	& img {
		max-width: ${({ isTab }) => (isTab ? '70px' : '38px')};
		height: ${({ isTab }) => (isTab ? '70px' : '38px')};
		box-shadow: inset 0px 0px 15px -5px #0f0e15a1;
	}
`;
const Text = styled.div<{ isTab: boolean }>`
	margin-top: ${({ isTab }) => (isTab ? '6px' : '0')};
`;
const Name = styled.div`
	& a {
		color: #333e48;
		transition: all 0.3s ease;

		&:hover {
			color: #424bec;
			text-decoration: none;
		}
	}
`;
const Bold = styled.span`
	font-weight: 600;
`;
const Description = styled(Paragraph)`
	font-size: 14px;
`;

export default SpecialistInfo;
