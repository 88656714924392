import React from 'react';
import styled from 'styled-components';

import { CreatorInArticle } from 'apiFolder/directus-main';

import SpecialistInfo from './SpecialistInfo';

interface ISpecialists {
	authors: CreatorInArticle[];
	reviewers: CreatorInArticle[];
	contributors: CreatorInArticle[];
	publishedAt: string;
	lastReviewed: string;
	contributedAt: string;
	isTab?: boolean;
}

const SpecialistsBlock: React.FC<ISpecialists> = ({
	authors,
	reviewers,
	contributors,
	publishedAt,
	lastReviewed,
	contributedAt,
	isTab,
}) => {
	return (
		<Specialists>
			<SpecialistInfo
				specialists={authors}
				dateBy={publishedAt}
				titlePref='Written by'
				textPref='Published on'
				isTab={isTab}
			/>
			<SpecialistInfo
				specialists={reviewers}
				dateBy={lastReviewed}
				titlePref='Medically reviewed by'
				textPref='Reviewed on'
				isTab={isTab}
			/>
			<SpecialistInfo
				specialists={contributors}
				dateBy={contributedAt}
				titlePref='Contributed by'
				textPref='Contribution made on'
				isTab={isTab}
			/>
		</Specialists>
	);
};

export const Specialists = styled.div`
	box-sizing: border-box;
	padding: 0 0 15px;
	width: 300px;
`;

export default SpecialistsBlock;
