/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Link } from 'gatsby';

import styled from 'styled-components';

import { ArticleWithCreators } from 'apiFolder/directus-main';
import { ArticleH1, Heading, Paragraph, SubHeading } from 'components/common/Styles';

import Header from 'components/common/header/Header';
import Footer from 'components/Footer/Footer';
import { ContentArticle } from 'components/Content';
import Seo from 'components/seo';
import TabsArticles from 'components/TabsArticles/TabsArticles';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import SpecialistsBlock from 'components/article/SpecialistsBlock';
import LastUpdated from 'components/article/LastUpdated';

interface Context {
	pageContext: {
		item: ArticleWithCreators;
	};
}

const articleTemplate: React.FunctionComponent<Context> = ({ pageContext: { item } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const [scrollTo, setScrollTo] = useState<string | null>(null);
	const {
		authors,
		reviewers,
		contributors,
		heading,
		content,
		metaTitle,
		metaDescription,
		publishedAt,
		lastUpdated,
		lastReviewed,
		subHeading,
		image,
		markup_schema,
		tableOfContents,
	} = item;

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	useEffect(() => {
		if (!scrollTo) return;

		const timer = setTimeout(() => {
			const blockScrollTo = document.querySelector(`[name="${scrollTo}"]`);

			if (blockScrollTo) {
				const offset = 100;

				window.scrollTo({
					top: blockScrollTo.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
					behavior: 'smooth',
				});

				setScrollTo(null);
			}
		}, 50);

		return () => clearTimeout(timer);
	}, [scrollTo]);

	return (
		<>
			<Seo title={metaTitle} description={metaDescription} articleImage={image!} markupSchema={markup_schema} />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle isOpen={isMenuOpen}>
						<ContentInner>
							<Main>
								<ArticleH1>{heading}</ArticleH1>
								<SubHeading>{subHeading}</SubHeading>
								{tableOfContents.length > 0 && (
									<TableOfContents>
										{tableOfContents.map((link, index) => (
											<TableWrap key={link.anchor_link}>
												<TableOfContentsLink
													href={link.anchor_link}
													onClick={() => setScrollTo(link.anchor_link.slice(1))}
												>
													{link.anchor_text}
												</TableOfContentsLink>
												{index !== tableOfContents.length - 1 && <Divider />}
											</TableWrap>
										))}
									</TableOfContents>
								)}
								<RightSideMob>
									<div>
										<SpecialistsBlock
											authors={authors}
											reviewers={reviewers}
											contributors={contributors}
											lastUpdated={lastUpdated}
											lastReviewed={lastReviewed}
										/>
										<RightSideLastUpdated>
											<LastUpdated updated_at={lastUpdated} />
										</RightSideLastUpdated>
									</div>
								</RightSideMob>
								<ReactMarkdownWrapper>
									<ReactMarkdown
										rehypePlugins={[rehypeRaw]}
										children={content}
										remarkPlugins={[remarkGfm]}
									/>
								</ReactMarkdownWrapper>
							</Main>
							<RightSide>
								<div>
									<SpecialistsBlock
										authors={authors}
										reviewers={reviewers}
										contributors={contributors}
										publishedAt={publishedAt}
										lastReviewed={lastReviewed}
										contributedAt={item.contributed_at}
									/>
									<RightSideLastUpdated>
										<LastUpdated updated_at={lastUpdated} />
									</RightSideLastUpdated>
								</div>
							</RightSide>
						</ContentInner>
						<Wrap>
							<TabsWrap>
								<TabsArticles item={item} />
							</TabsWrap>
							<Heading>Content Disclaimer</Heading>
							<Paragraph>
								This content is general in nature and is for informational purposes only - it does not
								constitute medical advice. Content on BookPhysio.com is not intended to be a substitute
								for professional medical advice, diagnosis, or treatment. Read more from our{' '}
								<Link to='/content-disclaimer'>Content Disclaimer</Link>.
							</Paragraph>
						</Wrap>
					</ContentArticle>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const ContentInner = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 80px;
	scroll-behavior: smooth;

	@media (max-width: 767px) {
		flex-direction: column;
		padding-top: 50px;
	}
`;
const Main = styled.div`
	flex: 0 1 auto;
	margin-right: 60px;
  import styled from 'styled-components';

  @media (max-width: 767px) {
		margin-right: 0px;
	}
`;

const TableOfContents = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	// margin: 60px 0 50px;
`;
const TableWrap = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const TableOfContentsLink = styled.a`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-family: MuseoSans;
	font-size: 19px;
	line-height: 1.4;
	font-weight: 500;
	color: ${({ theme }) => theme.palette.purp};
	text-decoration: none;
	transition: all 0.3s ease;
	padding: 10px 0;

	&:hover {
		text-decoration: underline;
	}

	@media (max-width: 767px) {
		font-size: 16px;
		line-height: 1;
	}
`;

const Divider = styled.div`
	display: flex;
	align-items: center;
	width: 1px;
	height: 22px;
	background-color: #bcbfc4;
	margin: 0 15px;

	@media (max-width: 767px) {
		margin: 0 12px;
	}
`;

const RightSide = styled.div`
	flex: 0 0 300px;
	width: 300px;
	display: flex;
	flex-direction: column;
	margin: 0 0 40px;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: #333e48;
	@media (max-width: 767px) {
		position: absolute;
		width: 0;
		height: 0;
		display: none;
	}
`;
const RightSideMob = styled.div`
	display: none;
	@media (max-width: 767px) {
		flex: 0 0 300px;
		width: 300px;
		margin: 40px auto 40px 0;
		display: flex;
		flex-direction: column;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		color: #333e48;
	}
`;
const RightSideLastUpdated = styled.div``;

const ReactMarkdownWrapper = styled.div`
	margin: 50px 0 100px;
	& p {
		font-weight: 300;
		font-size: 17px;
		color: #333e48;
		line-height: 1.5;
		margin: 25px 0;
		overflow: hidden;
		text-overflow: ellipsis;
		@media (max-width: 767px) {
			font-size: 16px;
		}
		& a {
			color: ${({ theme }) => theme.palette.purp};
			margin: 0 2px;
			transition: all 0.3s ease;

			&:hover {
				color: ${({ theme }) => theme.palette.main};
				text-decoration: none;
			}
		}
	}
	& h2 {
		font-family: MuseoSans;
		font-size: 32px;
		line-height: 1.2;
		font-weight: 600;
		color: #152231;
		margin: 62px 0 30px;
		@media (max-width: 767px) {
			font-size: 24px;
		}
	}
	& h3 {
		font-family: MuseoSans;
		font-size: 24px;
		line-height: 1.4;
		font-weight: 600;
		color: #152231;
		margin: 30px 0 30px;
		@media (max-width: 767px) {
			font-size: 21px;
		}
	}

	& h4 {
		font-family: MuseoSans;
		font-size: 21px;
		line-height: 1.4;
		font-weight: 600;
		color: #152231;
		margin: 25px 0 25px;
		@media (max-width: 767px) {
			font-size: 18px;
		}
	}

	& h5 {
		font-family: MuseoSans;
		font-size: 18px;
		line-height: 1.4;
		font-weight: 600;
		color: #152231;
		margin: 25px 0 25px;
		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	& img {
		max-width: 750px;
		width: 100%;
		object-fit: cover;
	}

	& ul {
		list-style-type: none;
		margin: 10px 0 20px 20px;

		& > li {
			position: relative;
			line-height: 1.5;
			&:before {
				content: '';
				position: absolute;
				top: 0.6em;
				// top: 9px;
				// top: 0.5rem;
				left: -15px;
				width: 5px;
				height: 5px;
				background: #000000;
				border-radius: 50%;
			}
		}
	}
	& ol {
		& li {
			margin: 0 0 0 15px;
		}
	}

	& a {
		color: #424bec;
		transition: all 0.3s ease;

		&:hover {
			color: #000000;
			text-decoration: none;
		}
	}

	& td,
	th {
		padding-right: 10px;
		padding-bottom: 10px;
	}

	@media (max-width: 1052px) {
		margin: 50px 0 70px;
	}
`;

const Wrap = styled.div`
	max-width: 750px;
`;
const TabsWrap = styled.div`
	margin-bottom: 100px;
	@media (max-width: 767px) {
		margin-bottom: 0px;
	}
`;

export default articleTemplate;
