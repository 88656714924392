import styled from 'styled-components';

export const H1 = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21';
	font-size: 34px;
	line-height: 1.2;
	font-weight: 600;
	color: #152231;
	@media (max-width: 767px) {
		font-size: 24px;
	}
`;
export const ArticleH1 = styled.h1`
	font-family: MuseoSans;
	font-size: 42px;
	line-height: 1.2;
	font-weight: 600;
	color: #152231;
	@media (max-width: 767px) {
		font-size: 28px;
	}
`;
export const H2 = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21';
	font-size: 32px;
	line-height: 1.2;
	font-weight: 600;
	color: #152231;
	margin: 62px 0 30px;
	@media (max-width: 767px) {
		font-size: 20px;
	}
`;
export const ArticleH2 = styled.h2`
	font-family: MuseoSans;
	font-size: 32px;
	line-height: 1.2;
	font-weight: 600;
	color: #152231;
	margin: 62px 0 30px;
	@media (max-width: 767px) {
		font-size: 24px;
	}
`;
export const H3 = styled.h3`
	font-family: 'SharpGrotesk-MediumNo21';
	font-size: 30px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 30px 0;
	@media (max-width: 767px) {
		font-size: 18px;
	}
`;

export const ArticleH3 = styled.h3`
	font-family: MuseoSans;
	font-size: 24px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 30px 0 30px;
	@media (max-width: 767px) {
		font-size: 21px;
	}
`;

export const H4 = styled.h4`
	font-family: 'SharpGrotesk-MediumNo21';
	font-size: 24px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 25px 0;
	@media (max-width: 767px) {
		font-size: 16px;
	}
`;
export const ArticleH4 = styled.h4`
	font-family: MuseoSans;
	font-size: 21px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 25px 0 25px;
	@media (max-width: 767px) {
		font-size: 18px;
	}
`;
export const H5 = styled.h5`
	font-family: 'SharpGrotesk-MediumNo21';
	font-size: 20px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 18px 0;
`;
export const ArticleH5 = styled.h5`
	font-family: MuseoSans;
	font-size: 18px;
	line-height: 1.4;
	font-weight: 600;
	color: #152231;
	margin: 25px 0 25px;
	@media (max-width: 767px) {
		font-size: 16px;
	}
`;
export const SubHeading = styled.div`
	font-family: MuseoSans;
	font-size: 25px;
	line-height: 1.4;
	font-weight: 300;
	color: #152231;
	margin: 25px 0;
	@media (max-width: 767px) {
		font-size: 20px;
	}
`;

export const Paragraph = styled.div`
	font-weight: 300;
	font-size: 17px;
	color: #333e48;
	line-height: 1.5;
	margin: 25px 0;
	& a {
		color: ${({ theme }) => theme.palette.purp};
		margin: 0 2px;
		transition: all 0.3s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
	@media (max-width: 767px) {
		font-size: 16px;
	}
`;

export const ParagraphBold = styled(Paragraph)`
	font-weight: 600;
`;

export const Heading = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #333e48;
	margin-bottom: 30px;
`;
