import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { getMonthDateYear } from 'helpers/getMonthDateYear';
import { ArticleWithCreators } from 'apiFolder/directus-main';
import SpecialistInfo from 'components/article/SpecialistInfo';

interface Props {
	item: ArticleWithCreators;
}

const ArticleTabs: React.FunctionComponent<Props> = ({ item }) => {
	const NavArray = ['Sources', 'Authors', 'Medical reviewers', 'Contributors'];

	const objectsNavArray = NavArray.map((navItem) => ({
		isActive: false,
		name: navItem,
	}));
	objectsNavArray[0] = { ...objectsNavArray[0], isActive: true };

	const [NavWords, setNavWords] = useState(objectsNavArray);
	const [state, setState] = useState(objectsNavArray[0].name);

	const onNavChange = (word: string) => {
		setState(word);
		setNavWords(
			NavWords.map((m) => {
				m.isActive = m.name === word;
				return m;
			}),
		);
	};

	return (
		<BlockStyled>
			<Inner>
				<Navigation>
					<NavigationItems>
						{(item.contributors.length ? NavWords : NavWords.slice(0, NavWords.length - 1)).map((word) => (
							<NavItem key={word.name} isActive={word.isActive} onClick={() => onNavChange(word.name)}>
								<NavItemText>{word.name}</NavItemText>
							</NavItem>
						))}
					</NavigationItems>
					<NavigationBorder />
				</Navigation>
				{state === 'Sources' && (
					<ArticleContent>
						{item.lastReviewed && (
							<SourceTitle>Last medically reviewed on {getMonthDateYear(item.lastReviewed)}</SourceTitle>
						)}
						<SourceContent>
							BookPhysio.com has strict sourcing guidelines and relies on peer-reviewed studies, academic
							research institutions, and medical associations. We avoid using tertiary references. You can
							learn more about how we ensure our content is accurate and current by reading our{' '}
							<Link to='/editorial-policy'>editorial policy</Link>.
						</SourceContent>
						<List>
							{item.sources.length
								? item.sources.map((citation, index) => {
										return (
											<Item key={`${citation.citation.slice(0, 10)}${index}`}>
												<Number>{index + 1}.</Number>
												<Block>
													<ItemContent>{citation.citation}</ItemContent>
													{citation.source_url && (
														<a
															href={citation.source_url}
															target='_blank'
															rel='noopener noreferrer'
														>
															{citation.source_url}
														</a>
													)}
												</Block>
											</Item>
										);
								  })
								: null}
						</List>
					</ArticleContent>
				)}
				{state === 'Authors' && (
					<Wrap>
						<SpecialistInfo
							specialists={item.authors}
							dateBy={item.publishedAt}
							titlePref='Written by'
							textPref='Published on'
							isTab
						/>
					</Wrap>
				)}
				{state === 'Medical reviewers' && (
					<Wrap>
						<SpecialistInfo
							specialists={item.reviewers}
							dateBy={item.lastReviewed}
							titlePref='Medically reviewed by'
							textPref='Reviewed on'
							isTab
						/>
					</Wrap>
				)}
				{state === 'Contributors' && (
					<Wrap>
						<SpecialistInfo
							specialists={item.contributors}
							dateBy={item.contributed_at}
							titlePref='Contributed by'
							textPref='Contribution made on'
							isTab
						/>
					</Wrap>
				)}
			</Inner>
		</BlockStyled>
	);
};
const BlockStyled = styled.div`
	margin-bottom: 50px;
`;
const Inner = styled.div`
	& a {
		width: 100%;
		color: ${({ theme }) => theme.palette.purp};
		margin: 0 2px;
		transition: all 0.3s ease;

		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;
const NavItem = styled.div<{ isActive: boolean }>`
	cursor: pointer;
	font-weight: 300;
	font-size: 16px;
	text-align: center;
	border-bottom: ${({ isActive }) => (isActive ? '2px solid black' : 'none')};
	color: ${({ isActive }) => (isActive ? '#000' : '#9a9999')};
	z-index: 2;
	margin-left: 90px;
	&:first-child {
		margin: 0;
	}
	&:last-child {
		margin-left: 110px;
	}
	@media (max-width: 586px) {
		margin-left: 40px;
		&:last-child {
			margin-left: 40px;
		}
	}
	@media (max-width: 465px) {
		margin-left: 15px;
		&:last-child {
			margin-left: 15px;
		}
	}
`;
const NavItemText = styled.div`
	padding: 0 5px 10px;
	white-space: nowrap;
	font-weight: 400;
	@media (max-width: 465px) {
		font-size: 14px;
	}
`;
const Navigation = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	overflow: auto;
`;
const NavigationItems = styled.div`
	display: flex;
	overflow: auto;
`;

const NavigationBorder = styled.div`
	border-bottom: 2px solid #f0f0f1;
	width: 100%;
	position: absolute;
	bottom: 0;
	z-index: 1;
`;

const ArticleContent = styled.div``;
const Wrap = styled.div`
	margin-top: 40px;
	padding-bottom: 34px;
	font-weight: 300;
	font-size: 14px;
	line-height: 1.4;
	color: #333e48;
	& a {
		color: #333e48;
		transition: all 0.3s ease;
		&:hover {
			color: #424bec;
			text-decoration: none;
		}
	}
	& > div > div {
		margin-top: 51px;
		border-bottom: 1px solid #bdc5cd;
		&:last-child {
			border: none;
		}
		&:first-child {
			margin-top: 0px;
		}
	}
	@media (max-width: 767px) {
		padding-bottom: 0px;
	}
`;
const SourceTitle = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #748095;
	margin-top: 40px;
`;
const SourceContent = styled.div`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #748095;
	margin-top: 46px;
`;
const List = styled.ul`
	margin-top: 40px;
	width: 100%;
`;
const Item = styled.li`
	display: flex;
	margin-bottom: 30px;
	padding-left: 40px;
	margin-left: 40px;
	& a {
		width: 100%;
		color: #4543ec;
		margin: 0 2px;
		transition: all 0.3s ease;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			color: #748095;
			text-decoration: none;
		}
	}
	@media (max-width: 510px) {
		padding-left: 0;
		margin-left: 15px;
	}
`;
const Number = styled.span`
	flex: 0 0 15px;
	width: 15px;
	font-weight: 300;
	margin-right: 6px;
`;
const Block = styled.div`
	flex: 0 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const ItemContent = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	margin-bottom: 12px;
`;

export default ArticleTabs;
